<template>
    <div>
        <h2><router-link :to="{ name: 'stock' }">สต็อก</router-link> > <router-link :to="{ name: 'stock_view', params: { product_id: inventory.product_id } }">แยกตามคลัง {{ !!inventory && !!inventory.warehouse ? '(' + inventory.warehouse.name + ')' : '' }}</router-link> > ประวัติ</h2>       
        <v-card class="mt-4">
          <v-row class="align-center">
            <v-col cols="1"
            >
              <v-btn
              icon
              @click="$router.go(-1)"
              ><v-icon>{{ mdiChevronLeft }}</v-icon></v-btn>
            </v-col>
            <template v-if="!!inventory">
              <v-col cols="1">
                <product-image :image="inventory.product.images[0] ? inventory.product.images[0].URI : null" />
              </v-col>
              <v-col>
                {{ inventory.product.name }}
              </v-col>
              <v-col>
                {{ inventory.product.sku }}
              </v-col>
              <v-col>
                {{ inventory.product.brand ? inventory.product.brand.name : '-' }}
              </v-col>
              <v-col cols="1">
                {{ inventory.quantity }}
              </v-col>
              <v-col cols="1">
                {{ inventory.reserved }}
              </v-col>
              <v-col cols="1">
                {{ inventory.quantity - inventory.reserved }}
              </v-col>
            </template>            
          </v-row>
          <delay-datatable
            :headers="datatable.headers"
            :options.sync="datatable.options"            
            :url="'/inventory/' + $route.params.stock_id + '/history'"            
            :queryParams="getDatatableQueryParams"
            @loadState="loadDatatableState"            
            ref="mainDatatable"
          >

          <template          
            v-slot:item.created_at="{ item }"
          >   
            {{ !!item.created_at ? getLocalTime(item.created_at) : '-' }}
          </template>

          <template          
            v-slot:item.creator="{ item }"
          >   
            {{ !!item.creator && !!item.creator.name ? item.creator.name + ' ' + item.creator.lastname : 'ระบบ' }}
          </template>   

          <template          
            v-slot:item.quantity="{ item }"
          >   
            <v-chip
              color="success"
              text-color="white"
              v-if="getTypeBool(item.type)"
              class="justify-center"
              style="min-width: 75px"
            >
              + {{ item.quantity }}
            </v-chip>
            <v-chip
              color="primary"
              text-color="white"
              v-else
              class="justify-center"
              style="min-width: 75px"
            >
              - {{ item.quantity }}
            </v-chip>
          </template>       

          <template          
            v-slot:item.orderNumber="{ item }"
          >   
            {{ !!item.order ? item.order.orderNumber : '-' }}
          </template>
          </delay-datatable>
        </v-card>      
    </div>
</template>
<script>
import ProductImage from '@/components/ProductImage.vue'
import { asyncGet, asyncUpdate } from '@/helpers/asyncAxios'
import { convertTimestampToAooDefault, isTypePositive } from '@/helpers/converter'
import { mdiChevronLeft, mdiHistory, mdiPlaylistEdit } from '@mdi/js'
export default {
  data() {
    return {
      inventory: null,
      editDialog: {
        isOpen: false,
        isSubmitting: false,
        warehouse: '',
        currentQuantity: 0,
        currentReserved: 0,
        newQuantity: null,
        inventoryId: null,
      },
      datatable: {
        brand_id: null,
        warehouse_id: null,
        search: null,
        options: {
          sortBy: ['created_at'],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 10,
        },
        headers: [
          {
            text: 'วันที่',
            value: 'created_at',
          },
          {
            text: 'เลขที่อ้างอิง',
            value: 'orderNumber',
            sortable: false,
          },
          {
            text: 'ตั้งต้น',
            value: 'before',
          },
          {
            text: 'เข้า/ออก/ปรับ',
            value: 'input',
          },
          {
            text: 'คงเหลือ',
            value: 'after',
          },
          {
            text: 'เปลี่ยนแปลง',
            value: 'quantity',
          },
          {
            text: 'โดย',
            value: 'creator',
            sortable: false,
          },
        ],
      },
      mdiPlaylistEdit,
      mdiHistory,
      mdiChevronLeft,
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        warehouse_id: this.datatable.warehouse_id,
      }
    },
    getQuantitySum() {
      if (!this.$refs.mainDatatable.data || this.$refs.mainDatatable.data.length == 0) return 0

      return this.$refs.mainDatatable.data
        .map(data => data.quantity)
        .reduce((total, currentQuantity) => {
          return total + currentQuantity
        })
    },
    getReservedSum() {
      if (!this.$refs.mainDatatable.data || this.$refs.mainDatatable.data.length == 0) return 0

      return this.$refs.mainDatatable.data
        .map(data => data.reserved)
        .reduce((total, currentReserved) => {
          return total + currentReserved
        })
    },
  },
  async created() {
    const inventory_id = this.$route.params.stock_id

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const response = await asyncGet('/inventory/' + inventory_id)

      this.inventory = response

      // this.$refs.mainDatatable.reload()
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    getLocalTime(timestamp) {
      return convertTimestampToAooDefault(timestamp)
    },
    getTypeBool(type) {
      return isTypePositive(type)
    },
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    callEditModal({ quantity, reserved, warehouse, id }) {
      this.editDialog.isOpen = true
      this.editDialog.isSubmitting = false

      this.editDialog.currentQuantity = quantity
      this.editDialog.currentReserved = reserved
      this.editDialog.warehouse = warehouse.name
      this.editDialog.newQuantity = null
      this.editDialog.inventoryId = id
    },
    async updateStock() {
      this.editDialog.isSubmitting = true
      const diff = this.editDialog.newQuantity - this.editDialog.currentQuantity

      try {
        await asyncUpdate('/inventory/' + this.editDialog.inventoryId, {
          diff: diff,
        })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.$refs.mainDatatable.reload()
      this.editDialog.isSubmitting = false
      this.editDialog.isOpen = false
    },
  },
  components: {
    ProductImage,
  },
}
</script>
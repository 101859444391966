var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',[_c('router-link',{attrs:{"to":{ name: 'stock' }}},[_vm._v("สต็อก")]),_vm._v(" > "),_c('router-link',{attrs:{"to":{ name: 'stock_view', params: { product_id: _vm.inventory.product_id } }}},[_vm._v("แยกตามคลัง "+_vm._s(!!_vm.inventory && !!_vm.inventory.warehouse ? '(' + _vm.inventory.warehouse.name + ')' : ''))]),_vm._v(" > ประวัติ")],1),_c('v-card',{staticClass:"mt-4"},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiChevronLeft))])],1)],1),(!!_vm.inventory)?[_c('v-col',{attrs:{"cols":"1"}},[_c('product-image',{attrs:{"image":_vm.inventory.product.images[0] ? _vm.inventory.product.images[0].URI : null}})],1),_c('v-col',[_vm._v(" "+_vm._s(_vm.inventory.product.name)+" ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm.inventory.product.sku)+" ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm.inventory.product.brand ? _vm.inventory.product.brand.name : '-')+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.inventory.quantity)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.inventory.reserved)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.inventory.quantity - _vm.inventory.reserved)+" ")])]:_vm._e()],2),_c('delay-datatable',{ref:"mainDatatable",attrs:{"headers":_vm.datatable.headers,"options":_vm.datatable.options,"url":'/inventory/' + _vm.$route.params.stock_id + '/history',"queryParams":_vm.getDatatableQueryParams},on:{"update:options":function($event){return _vm.$set(_vm.datatable, "options", $event)},"loadState":_vm.loadDatatableState},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!!item.created_at ? _vm.getLocalTime(item.created_at) : '-')+" ")]}},{key:"item.creator",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!!item.creator && !!item.creator.name ? item.creator.name + ' ' + item.creator.lastname : 'ระบบ')+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [(_vm.getTypeBool(item.type))?_c('v-chip',{staticClass:"justify-center",staticStyle:{"min-width":"75px"},attrs:{"color":"success","text-color":"white"}},[_vm._v(" + "+_vm._s(item.quantity)+" ")]):_c('v-chip',{staticClass:"justify-center",staticStyle:{"min-width":"75px"},attrs:{"color":"primary","text-color":"white"}},[_vm._v(" - "+_vm._s(item.quantity)+" ")])]}},{key:"item.orderNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(!!item.order ? item.order.orderNumber : '-')+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }